import React from "react"

export default function TermsAndConditions() {
  return (
    <div className="container pb-5 pt-5 mt-5">
      <h4>Terms and Conditions</h4>
      <p>
        This website is designed, updated and maintained by Unifiers Social
        Ventures Pvt Ltd. Though all efforts have been made to ensure the
        accuracy of the content on this website, the same should not be
        construed as a statement of law or used for any legal purposes. In case
        of any ambiguity or doubts, users are advised to verify/check with
        Unifiers Social Ventures Pvt Ltd., B-100, LGF, Sarvodya Enclave and to
        obtain appropriate professional advice. By registering yourself with
        Indian ESG Network it will be deemed that you have read, understood and
        agreed to fully abide by all the terms and conditions of the website as
        contained herein.
      </p>
      <p>
        The right to use the content and services of the Indian ESG Network is
        on permission basis as per the terms and conditions contained herein.
        Except the usage of the content and services during the registered
        period, the registered users shall not have any right or title over the
        website or any of its contents. Under no circumstances will Unifiers
        Social Ventures Pvt Ltd. be liable for any expense, loss or damage
        including, without limitation, indirect or consequential loss or damage,
        or any expense, loss or damage whatsoever arising from use, or loss of
        use, of data, arising out of or in connection with the use of this
        website. Indian ESG Network and Unifiers Social Ventures Pvt Ltd
        reserves the right, in its sole discretion, to terminate or refuse all
        or part of its services, for any reason without notice to you.
      </p>
      <p>
        These terms and conditions shall be governed by and construed in
        accordance with the Indian Laws. Any dispute arising under these terms
        and conditions shall be subject to the jurisdiction of the courts of New
        Delhi, India. The information posted on this website could include
        hypertext links or pointers to information created and maintained by
        non-Government / private organizations. Indian ESG Network is providing
        these links and pointers solely for your information and convenience.
        When you select a link to an outside website, you are leaving the Indian
        ESG Network website and are subject to the privacy and security policies
        of the owners/sponsors of the outside website. Unifiers Social Ventures
        Pvt Ltd., does not guarantee the availability of such linked pages at
        all times.
      </p>
    </div>
  )
}
